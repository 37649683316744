<template>
  <Dropdown :name="name" width="lg" dropdown-type="locales" name="headermobile">
    <template #button-content="{ onClickOpen }">
      <a
        href="#"
        class="flex items-center nav-link"
        @click.prevent="() => onDropdownOpen(onClickOpen)"
      >
        <div class="flex items-center justify-center">
          <div class="relative">
            <BagIcon
              :color="color"
              class="inline-block w-6 h-6 nav-link hover:opacity-60"
            />
            <span
              v-if="cartTotalItems > 0"
              class="absolute z-40 inline-flex w-2 h-2 text-sm rounded-full shadow-lg bg-primary top-1.5 end-0 ring-2 ring-white"
            />
          </div>
          <span
            :class="textColor"
            v-if="cartTotalItems > 0"
            class="hidden mt-1 ml-1 text-base font-light lg:inline-block"
          >
            {{ $t('common.cart') }} - {{ cartTotalItems }}
          </span>
        </div>
      </a>
    </template>

    <template #default="{ onClickClose }">
      <LoadingOverlay :is-active="cartStore.loading">
        <!-- Cart -->
        <div class="px-4 py-3 font-suiza-regular">
          <!-- bag is empty -->
          <p
            v-if="cartTotalItems === 0"
            class="mt-10 text-center text-gray-400"
          >
            {{ $t('common.your_bag_is_empty') }}
          </p>
          <!-- bag haves items -->

          <ul v-else>
            <li
              v-for="item in cartItems.slice(0, 3)"
              :key="item.id"
              class="relative flex items-center py-2 border-b"
            >
              <img
                v-if="item.attributes.picture"
                :src="item.attributes.picture"
                :alt="item.name"
                class="object-contain w-16 h-16 border-transparent me-3"
              />
              <div v-else class="flex items-center w-16 h-16 me-3">
                <ImageIcon class="h-8 mx-auto text-gray-200" />
              </div>
              <p class="mx-3 text-sm">
                {{ item.name }}
              </p>
              <a
                href="#"
                class="ms-auto text-danger"
                @click.prevent="removeCartItem(item.uuid)"
              >
                <CloseIcon class="w-6 h-6 stroke-red hover:opacity-50" />
              </a>
            </li>
          </ul>
          <!-- if it haves more than 3 items -->
          <p
            v-if="cartItems.length > 3"
            class="text-xs text-center text-gray-400"
          >
            {{ $t('common.3_more_items_in_your_bag') }}
          </p>
          <Btn
            v-if="cartItems.length > 0"
            color="black"
            class="mt-3"
            block
            @click.prevent="() => prepareForCheckOut(onClickClose)"
            v-text="$t('common.checkout')"
          />
        </div>
      </LoadingOverlay>

      <!-- User info -->
      <ul class="px-4 pb-3 font-suiza-regular">
        <!-- if user is logged -->
        <li
          v-if="status == 'authenticated'"
          key="loggedIn"
          class="py-2 border-b"
        >
          <nuxt-link
            v-if="data.data"
            @click.prevent="onClickClose"
            class="block text-gray-500 hover:no-underline"
          >
            {{ data.data.given_name }}
          </nuxt-link>
          <nuxt-link
            v-if="data.data"
            @click.prevent="onClickClose"
            class="block text-blue-500 hover:no-underline hover:text-blue-300"
          >
            {{ data.data.email }}
          </nuxt-link>
        </li>
        <!-- if user is not logged -->
        <template v-else>
          <li class="py-2 border-b">
            <span
              @click="() => openSignInModal(onClickClose)"
              class="text-gray-600 cursor-pointer hover:no-underline"
            >
              {{ $t('common.login') }}
            </span>
          </li>
          <li class="py-2 border-b">
            <div
              @click="() => openSignUpModal(onClickClose)"
              class="text-gray-600 cursor-pointer hover:no-underline"
            >
              {{ $t('common.register') }}
            </div>
          </li>
        </template>

        <!-- no matter if logged or not -->
        <li class="py-2">
          <nuxt-link
            class="text-gray-600 hover:no-underline"
            :to="$i18nPath('/my-account/orders')"
            v-text="$t('common.my_orders')"
          />
        </li>
        <li class="py-2 border-t" @click.prevent="() => onClickClose()">
          <nuxt-link
            :to="$i18nPath('/my-account/bikes')"
            v-text="$t('common.my_bicycles')"
            class="text-gray-600 hover:no-underline"
          />
        </li>
        <!-- if user is logged -->
        <li v-if="status == 'authenticated'" class="pt-2 border-t">
          <a
            class="text-gray-600 hover:no-underline"
            href="#"
            @click.prevent="() => logoutNow(onClickClose)"
            v-text="$t('common.logout')"
          />
        </li>
      </ul>
    </template>
  </Dropdown>
</template>

<script setup>
import { useCartStore } from '@/store/cart'
import CloseIcon from '@/assets/svg/heroicons/x-circle.svg?component'
import ImageIcon from '@/assets/svg/heroicons/image.svg?component'
import BagIcon from '@/components/icons/BagIcon'
import { useModal } from 'vue-final-modal'
import SignInModal from '~/modals/SignInModal.vue'
import SignUpModal from '~/modals/SignUpModal.vue'
import { useTranslation } from 'i18next-vue'

const authData = useAuth()
const { data, status, signOut } = authData
const route = useRoute()
const cartStore = useCartStore()
const router = useRouter()
const nuxtApp = useNuxtApp()
const { t } = useTranslation()

const loading = ref(false)
const isBag = ref(route.path.includes('bag'))
const $i18nPath = nuxtApp.$i18nPath

const props = defineProps({
  color: {
    type: String,
    required: false,
    default: 'white',
  },
  textColor: {
    type: String,
    required: false,
    default: 'text-white',
  },
  name: {
    type: String,
    default: 'header',
  },
})

const cartTotalItems = computed(() => {
  return cartStore.items ? cartStore.items.length : 0
})

const cartItems = computed(() => {
  return cartStore.items
})

const cartUUID = computed(() => {
  return cartStore.uuid
})

onMounted(() => {
  if (!cartStore.init) {
    cartStore.fetchCartItems()
  }
})

function onDropdownOpen(onClickOpen) {
  onClickOpen()
}

const successCallback = () => {
  nuxtApp.$toast(t('common.item_has_been_removed_from_bag'))
}

async function removeCartItem(uuid) {
  cartStore.removeCartItem(uuid, successCallback)
}
function prepareForCheckOut(onClickClose) {
  onClickClose()
  console.log(status.value)
  if (status.value == 'authenticated') {
    router.push(nuxtApp.$i18nPath(`/bag`))
  } else {
    openSignInModal(onClickClose)
  }
}
async function logoutNow() {
  if (nuxtApp.$intercom) {
    nuxtApp.$intercom.shutdown()
    nuxtApp.$intercom.update({})
  }
  signOut({ callbackUrl: '/', external: false })
  nuxtApp.$toast(t('common.logout_successfully'))
  // window.location.reload()

  await router.push(nuxtApp.$i18nPath('/'))
}

function openSignInModal(onClickClose) {
  onClickClose()
  const { open, close } = useModal({
    component: SignInModal,
    attrs: {},
  })
  open()
}

function openSignUpModal(onClickClose) {
  onClickClose()
  const { open, close } = useModal({
    component: SignUpModal,
    attrs: {},
  })
  open()
}
</script>

<style lang="postcss" scoped>
.nav-link {
  @apply mr-0;
}
</style>
